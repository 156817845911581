@import "src/variables";
.ticket-table {
  width: 100%;
  margin-bottom: 32px;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    padding: 0 12px;
    &__item {
      font-size: 12px;
      font-weight: 500;
      color: $gray-500;
      min-width: 150px;
    }
  }
  &__row {
    padding: 6px 12px;
    background-color: $gray-0;
    border-radius: 6px;
    margin-top: 8px;
    box-shadow: 0 4px 8px -2px rgba(16, 24, 40, 0.1);
    font-weight: 400;
    font-size: 12px;
    color: $gray-900;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    &.active {
      background-color: #E2F1FF;
      font-weight: 500;
    }
    &__item {
      min-width: 150px;
    }
  }
  &__empty-continer {
    margin-top: 80px;
  }
}