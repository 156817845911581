@import "src/variables";
.change-password {
  font-family: 'Poppins', sans-serif;
  &__container {
    font-family: 'Poppins', sans-serif;
    margin: 64px 0;
  }
  &__block {
    padding-bottom: 24px;
    border-bottom: 1px solid $gray-300;
  }
  &__block-input {
    font-family: 'Poppins', sans-serif;
    display: flex;
    margin-top: 20px;
    padding-bottom: 4px;
    border-bottom: 1px solid $gray-300;
  }
  &__title {
    color: $gray-900;
    font-size: 18px;
    font-weight: 500;
  }
  &__text {
    color: $gray-500;
    font-size: 12px;
    font-weight: 400;
  }
  &__text-second {
    width: 280px;
    color: $gray-700;
    font-size: 14px;
    font-weight: 500;
  }
  &__input-container {
    margin-left: 32px;
    width: 512px;
  }
  &__btn-block {
    margin-top: 16px;
    display: flex;
    justify-content: end;
  }
}