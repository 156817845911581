@import "src/variables";
.ticket-view {
  &__container {
    padding: 24px;
    min-height: calc(100vh - 76px);
    width: 100%;  }
  &__title-container {
    display: flex;
    justify-content: space-between;
    padding-top: 16px;
    padding-bottom: 24px;
    border-bottom: 1px solid $gray-200;
    margin-bottom: 24px;
  }
  &__title {
    font-size: 30px;
    font-weight: 500;
    color: $gray-900;
  }
  &__edit-block {
    display: flex;
    margin-right: 64px;
  }
  &__edit-title {
    color: $gray-500;
    font-weight: 500;
    font-size: 12px;
  }
  &__edit-text {
    color: $gray-900;
    font-weight: 400;
    font-size: 14px;
  }
&__messages_container {
  width: calc(100% - 380px);
  margin-top: 40px;
  margin-right: 24px;
  display: flex;
  flex-direction: column;
  border: 1px solid $gray-300;
  border-radius: 12px;
  background-color: #F5FBFF;

}
  &__messages-header {
    background-color: $gray-0;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 24px;
    border-bottom: 1px solid $gray-300;
    &__title {
      font-size: 18px;
      line-height: 40px;
      font-weight: 500;
      color: $gray-900;
    }
  }
  &__messages-body {
    background-color: #F5FBFF;
    height: calc(100vh - 450px);
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }
  &__messages-wrapper {
    margin: 16px;
    display: flex;
    flex-direction: column;
    &.self {
      align-items: end;
    }
  }
  &__messages-sander {
    width: calc(100% - 40px);
    padding: 8px 12px;
    margin-top: 6px;
    background-color: $gray-100;
    border-radius: 0 8px 8px 8px;
    box-shadow: 0 1px 3px rgba(1, 0.094, 0.533, 0.2);
    &.self {
      background-color: $blue-900;
      color: $gray-0;
      border-radius: 8px 0 8px 8px;
    }
  }
  &__messages-title-wrapper {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 40px);
  }
  &__messages-title {
    font-size: 14px;
    font-weight: 500;
    color: $gray-700;
  }
  &__messages-date {
    font-size: 12px;
    font-weight: 400;
    color: $gray-500;
  }
  &__messages-input {
    width: calc(100% - 30px);
    background-color: #F5FBFF;
    padding-left: 16px;
    display: flex;
    align-items: start;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
  }
  &__messages-send {
    margin-left: 16px;
    margin-top: 2px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: $blue-100;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &-icon {
      margin-top: 2px;
      margin-right: 2px;
    }
    &:hover {
      background-color: #1A38B9;
    }
    &:disabled {
      background-color: $gray-300;
    }
  }
  &__messages-attachment {
    width: 164px;
    display: flex;
    padding: 14px;
    background-color: $gray-0;
    border: 1px solid $gray-0;
    border-radius: 8px;
    margin-top: 8px;
    margin-right: 8px;
    cursor: pointer;
    &:hover {
      border: 1px solid $blue-100;
      background-color: #F0F5FF;
    }
  }
  &__messages-file-title {
    font-size: 12px;
    font-weight: 500;
    color: $blue-100;
    width: 92px;
    overflow-wrap: break-word;
  }
  &__attahments-container {
    margin-top: 40px;
    min-width: 350px;
    display: flex;
    flex-direction: column;
    border: 1px solid $gray-300;
    border-radius: 12px;
  }
  &__attahments-header {
    padding: 20px 24px;
    border-bottom: 1px solid $gray-300;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__attahments-title {
    font-size: 18px;
    font-weight: 500;
    color: $gray-900;
  }
  &__attahments-image {
    border: 1px solid $gray-300;
    border-radius: 8px;
    width: 52px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
      background-color: $gray-100;
    }
  }
  &__attahments-body {
    height: calc(100vh - 450px);
    padding: 16px 24px;
    overflow-y: auto;
  }
  &__file-container {
    padding: 14px;
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
  }
  &__status-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 12px 0;
  }
  &__status-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__status-text {
    font-size: 10px;
    color: $gray-500;
    margin-right: 4px;
  }
  &__status-line {
    border-bottom: 1px solid $gray-300;
    flex: 1;
    margin: 0 16px;
  }
  &__category-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__category-text {
    font-size: 10px;
    font-weight: 600;
    color: $gray-500;
  }
  &__upload {
    height: 20px;
  }
  &__attachment-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    &.self {
      justify-content: end;
    }
  }
  &__attachment {
    display: flex;
    padding: 14px;
    background-color: $gray-0;
    border: 1px solid $gray-0;
    border-radius: 8px;
    margin-top: 8px;
    margin-right: 8px;
    cursor: pointer;
    &:hover {
      border: 1px solid $blue-100;
      background-color: #F0F5FF;
    }
  }
  &__file-list-container {
    background-color: #F5FBFF;
    padding: 8px 16px 0 16px;
    margin-bottom: 8px;

    display: flex;
    flex-wrap: wrap;
  }
  &__file-list-wrapper {
    padding: 2px 8px;
    background-color: $gray-0;
    border-radius: 4px;
    border: solid 1px $gray-200;
    font-size: 12px;
    font-weight: 500;
    color: $gray-700;
    margin-right: 6px;
  }
  .width-for-file {
    width: calc(100% - 40px)!important;
  }
  &__file-upload-block {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
      background-color:  $gray-100;
    }
  }
}

