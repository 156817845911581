.table {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  line-height: 150%;
  margin: 10px 0 0 0;
  border: 1px solid #e6e6e7;
  border-radius: 20px;

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 10px 0;
    box-shadow: 0px 2px 6px 0px rgba(19, 40, 81, 0.1);
    padding: 7px 16px;
    border-radius: 20px 20px 0 0;
  }

  &__title {
    color: #4d4d56;
    font-size: 12px;
    font-weight: 500;
  }

  &__total {
    color: #b3b3b7;
    font-size: 12px;
  }

  &__list {
    min-height: 150px;
    max-height: 150px;
    overflow-y: scroll;
    margin: 5px 5px 5px 5px;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 8px;
    border-radius: 4px;

    &:nth-child(odd) {
      background: #f6f9fa;
    }
  }

  &__item-title {
    color: var(--gray-800, #1a1a26);
    font-size: 14px;
    font-weight: 500;
  }

  &__item-id {
    color: #b3b3b7;
    font-size: 12px;
    font-weight: 400;
  }
}
