@import 'src/variables';
.custom-field {
  min-height: 57px;
  font-family: 'Poppins', sans-serif;
  position: relative;

  &__title {
    color: var(--grey-900, #01010e);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    padding: 0 0 3px 0;
    &.address {
      margin-bottom: -40px;
      padding: 0;
      line-height: 100%;
    }
    &.lg {
      font-size: 14px;
    }
  }
}

.phone-input {
  &:focus {
    outline: none !important;
  }
}

.input {
  height: 32px;
  border-radius: 8px;
  font-family: 'Poppins', sans-serif;
  border: 1px solid var(--grey-palette-100, #e6e6e7);
  background: var(--grey-palette-25, #f9f9f9);
  color: var(--neutral-600, #1a1a26);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  &.error {
    border: 1px solid $error-300;
    &:hover {
      border: 1px solid $error-300 !important;
    }
  }
  .ant-input {
    background: var(--grey-palette-25, #f9f9f9);
  }
}
.input-lg {
  height: 44px;
  border-radius: 8px;
  font-family: 'Poppins', sans-serif;
  border: 1px solid var(--grey-palette-100, #e6e6e7);
  background: var(--grey-palette-25, #f9f9f9);
  color: var(--neutral-600, #1a1a26);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  &.error {
    border: 1px solid $error-300;
    &:hover {
      border: 1px solid $error-300 !important;
    }
  }
  .ant-input {
    background: var(--grey-palette-25, #f9f9f9);
  }
  &:hover {
    border: 1px solid $blue-100 !important;
  }
  &:focus {
    border: 1px solid $blue-100 !important;
  }
}
.input-white {
  height: 40px;
  border-radius: 8px;
  font-family: 'Poppins', sans-serif;
  border: 1px solid var(--grey-palette-100, #e6e6e7);
  background: var(--grey-palette-25, white);
  color: var(--neutral-600, #9e9ea4);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  &.error {
    border: 1px solid var(--grey-palette-100, #da4c53);
  }
  .ant-input {
    background: var(--grey-palette-25, white);
  }
}
.input-white-small {
  height: 32px;
  border-radius: 4px;
  font-family: 'Poppins', sans-serif;
  border: 1px solid var(--grey-palette-100, #e6e6e7);
  background: var(--grey-palette-25, white);
  // color: var(--neutral-600, #9e9ea4);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  .ant-select-selection-placeholder {
    margin: 0 0 0 0 !important;
    font-size: 14px;
    color: var(--Neutral-600, #9e9ea4);
    font-family: 'Poppins', sans-serif;
    font-weight: 200;
    line-height: 24px;
  }

  .ant-input-prefix {
    width: 19px;
  }

  .ant-input {
    height: 22px;

    // background: #f8f8f8 !important;
  }

  .cancel-btn {
    width: 15px !important;
    margin: -4px 0 0 0;
    cursor: pointer;
  }

  .option-select {
    height: 25px !important;
    font-size: 14px;

    &__value {
      font-size: 12px;
    }
  }

  .option-select__image {
    width: 22px;
    height: 22px;
  }
  .option-select__id {
    margin: -11px 0 0 0 !important;
    font-size: 9px;
  }

  &.error {
    border: 1px solid var(--grey-palette-100, #da4c53);
  }
  .ant-input {
    background: var(--grey-palette-25, white);
  }

  .ant-select-selector {
    height: 30px !important;
  }
}

.input {
  .ant-select-selector {
    background-color: var(--grey-palette-25, #f9f9f9) !important;
  }

  .ant-select-selection-placeholder {
    margin: 0 0 0 0 !important;
    font-size: 14px;
    color: var(--Neutral-600, #9e9ea4);
    font-family: 'Poppins', sans-serif;
    font-weight: 200;
    line-height: 24px;
  }
}

.ant-input-password-icon {
  width: 16px;
}

.upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #e6e6e7;
  border-radius: 8px;
  padding: 12px 18px;
}

.ant-upload-drag-container {
  min-height: 100px;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ant-upload-btn {
  max-width: 120px !important;
  border-radius: 8px;
  border: 1px solid #e6e6e7 !important;
  font-family: 'Poppins', sans-serif;
  color: var(--grey-400, #808086);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 10px !important;
}

.uploaded-file {
  font-family: 'Poppins', sans-serif;
  width: 120px;
  height: 120px;
  border: 1px solid #e6e6e7;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.error {
    border: 1px solid #da4c53;
  }

  &__name {
    color: var(--gray-700, #34343e);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin: 8px 0 8px 0;
    max-width: 102px;
    height: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__bluetext {
    color: var(--primary-100, #1f44dd);
    font-weight: 500;
    font-size: 11px;
    cursor: pointer;
  }
}

.upload-text {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  margin: 5px 0 0 0;
  font-size: 11px;
  width: 99px;
  line-height: 10px;

  &__click {
    color: var(--primary-100, #1f44dd);
    font-size: 11px;
    font-weight: 500;
    line-height: 20px;
  }

  &__subtext {
    font-size: 9px;
    width: 98px;
    margin: 0 auto;
    padding: 7px 0 0 0;
    height: 32px;
  }
}

.loan {
  padding: 24px;
  background: #f7f7f7;
  border-radius: 8px;
  width: 740px;
  margin: 0 0 15px 0;

  &.error {
    border: 1px solid #da4c53;
  }

  &__title {
    color: var(--neutral-800, #1f2125);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  &__row {
    display: flex;
    border-radius: 12px;
    border: 1px solid var(--neutral-300, #f1f1f1);
    background: var(--neutral-100, #fff);
    padding: 8px;
    flex-wrap: wrap;
    width: 100%;
  }

  &__item {
    border-radius: 1000px;
    padding: 0 16px;
    height: 26px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 8px 8px 0 0;
    background: var(--neutral-200, #f7f7f7);
    color: var(--neutral-800, #1f2125);
    transition: all 0.5s;
    cursor: pointer;

    &-image {
      margin: 0 5px 0 0;
      height: 0;
      transition: all 0.3s;

      &.active {
        height: 15px;
        transition: all 0.3s;
      }
    }

    &.active {
      background: var(--accent-500, #3a5be0);
      color: var(--neutral-100, #fff);
      transition: all 0.5s;
    }
  }
}

.appLogo {
  .ant-upload-drag {
    height: 120px !important;
  }
  .upload-text {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    margin: 5px 0 0 0;
    font-size: 13px;
    width: 99px;
    line-height: 17px;

    &__subtext {
      width: 100px;
      height: 65px;
    }
  }
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
  }

  &__image-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 149px;
  }
  .ant-upload-drag {
    .ant-upload-btn {
      width: 432px !important;
      max-width: 100% !important;
      height: 108px !important;
      max-height: 108px !important;
      padding: 10px !important;
      margin-left: auto;
    }
  }

  .error-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    .uploaded-file__name {
      margin: 0;
    }
  }
}

.error-text-app-logo {
  width: 432px !important;
}

.image-dragger {
  width: 25px;
}
