@import "src/variables";
.employees {
  &__title_row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 26px;
  }
  &__title {
    color: var(--grey-800, #1a1a26);
    font-size: 18px;
    font-weight: 500;
  }
  &__bage {
    margin-left: 8px;
    font-size: 12px;
    font-weight: 500;
    color: $blue-100;
    line-height: 18px;padding: 2px 8px;
    border-radius: 16px;
    background-color: rgba(31, 68, 221, 0.05);
  }
  &__table {
    font-family: 'Poppins', sans-serif;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 64px 0 0 0;
    min-height: 80vh;
  }
  &__empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__title {
      color: $gray-900;
      font-size: 16px;
      font-weight: 500;
      margin-top: 16px;
    }
    &__text {
      color: $gray-500;
      margin-top: 4px;
      margin-bottom: 24px;
    }
  }
  &__name {
    font-size: 16px;
    color: #101828;
    font-weight: 500;
  }
  &__id {
    color: #667085;
    font-size: 12px;
  }
  &__main-user {
    margin-bottom: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    &__logo {
      object-fit: contain;
      height: 40px;
      margin-right: 12px;
      border-radius: 4px;
      aspect-ratio: 2/1;
    }
  }
}
.team-table-container {
  border: 1px solid $gray-200;
  border-radius: 8px;
  overflow: hidden;
}
.team-table {
  border-collapse: collapse;
  width: 100%;
  &__header-item {
    text-align: left;
    font-size: 12px;
    color: #667085;
    background-color: #F9FAFB;
    height: 44px;
    padding: 0 24px;
  }
  &__name {
    color: #101828;
    font-weight: 500;
  }
  &__id {
    font-size: 12px;
  }
  &__row {
    border: none;
    background-color: white;
    &:nth-child(even) {
      background-color: #F9FAFB;
    }
  }

  &__row-cell {
    font-size: 14px;
    color: #667085;
    height: 72px;
    padding: 0 24px;
  }
  &__status {
    display: inline-flex;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    border-radius: 16px;
    padding: 2px 8px;
  }
  &__dot {
    font-size: 22px;
    line-height: 18px;
    display: inline-flex;
    margin-top: -1px;
  }
  &__icon-wrapper {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: #F2F4F7;
    }
  }
  &__arrow {
    cursor: pointer;
  }
}
.team-table__status.active {
  background-color: $success-100;
  color: #027A48;
}
.team-table__dot.active {
  color: #12B76A;
}
.team-table__status.paused {
  background-color: $gray-200;
  color: #344054;
}
.team-table__dot.paused {
  color: #667085;
}
.team-table__status.pending {
  background-color: $purple-100;
  color: #5925DC;
}
.team-table__dot.pending {
  color: #7A5AF8;
}