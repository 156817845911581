@import "src/variables";
.notification {
  &__container {
    font-family: 'Poppins', sans-serif;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 400px;
    height: 100px;
    background-color: white;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 12px 16px rgba(16, 24, 40, 0.1);
    display: flex;
  }
  &__title {
    color: $gray-900;
    font-size: 14px;
    font-weight: 500;
  }
  &__text {
    color: $gray-500;
  }
}