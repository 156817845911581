@import "src/variables";
.my-profile {
  &__container {
    font-family: 'Poppins', sans-serif;
    padding-top: 62px;
  }
  &__name {
    font-size: 18px;
    font-weight: 500;
    color: $gray-900;
  }
  &__id {
    font-size: 14px;
    font-weight: 400;
    color: $gray-500;
  }
  &__body {
    margin-top: 24px;
    background-color: $gray-0;
    border: 1px solid $gray-200;
    border-radius: 12px;
    box-shadow: 2px 6px 24px rgba(13, 21, 55, 0.05);
  }
  &__header {
    height: 80px;
    padding: 0 24px;
    background-color: $gray-0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $gray-200;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
  &__header-title {
    font-size: 18px;
    font-weight: 500;
    color: $gray-900;
  }
  &__body-info {
    padding: 24px;
    display: flex;
    &__block {
      margin-right: 48px;
    }
    &__title {
      font-size: 12px;
      font-weight: 500;
      color: $gray-500;
    }
    &__text {
      font-size: 14px;
      font-weight: 500;
      color: $gray-900;
    }
  }
}
