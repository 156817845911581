@import 'src/variables';
.OfferItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  &.offer {
    .OfferItem__item {
      &:hover {
        border: none;
      }
    }
  }

  &__line {
    border-bottom: 1px solid #e5ebfb;
  }

  &__i {
    position: absolute;
    top: 18px;
    right: 24px;
    cursor: pointer;
  }

  &__openItem {
    background: white;
    margin: 5px 0 0 0;
    border-bottom: 1px solid #e5ebfb;
    background: #fff;
    border-radius: 8px 8px 0 0;
    width: 100%;

    &.open {
      max-height: 9999px;
      transition: all 0.5s;
    }

    &.closed {
      max-height: 0px;
      overflow: hidden;
      transition: all 0.2s;
      border-bottom: none;
    }
  }

  &__plus {
    border-radius: 8px;
    border: 1px solid var(--Blue-100, #1f44dd);
    background: var(--Blue-100, #1f44dd);
    padding: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      background: var(--Blue-100, #496af0);
      transition: all 0.3s;
    }
  }

  &__minus {
    border-radius: 8px;
    border: 1px solid var(--Blue-100, #1f44dd);
    background: var(--Blue-100, #ffffff);
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      background: var(--Blue-100, #edf0fc);
      transition: all 0.3s;
    }
  }

  &__selected {
    border: 2px solid var(--Gradient, #42d1d6);
  }

  &__logo {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    object-fit: cover;
    margin: 0 8px 0 0;
  }

  &__text {
    color: #1a1a26;
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 16px;
    font-weight: 500;
    margin: 0 0 4px 0;
  }

  &__item {
    width: 100%;
    height: 64px;
    border-radius: 8px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    color: var(--Gray-800, #1a1a26);
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    &:hover {
      // border: 2px solid var(--Gradient, #32a5e2);
    }
  }

  &__item-border {
    border: 2px solid #e5ebfb;
  }

  &__head {
    display: flex;
    align-items: center;
    position: relative;
  }

  &__wrap {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px 16px 0;
    border-bottom: 1px solid #e5ebfb;
    height: 55px;
  }
  &__title {
    padding: 16px 24px;
    min-height: 55px;
    display: flex;
    color: var(--Gray-800, #1a1a26);
    font-size: 16px;
    font-weight: 400;
  }
  &__offer_name {
    display: flex;
    align-items: center;
    margin-left: 16px;
    background-color: rgba(179, 179, 183, 0.2);
    color: #67666e;
    font-size: 10px;
    padding: 2px 6px;
    border-radius: 4px;
  }

  &__status {
    color: #017130;
    font-size: 12px;
    font-weight: 400;
  }

  &__option {
    cursor: pointer;
    border: 1px solid white;

    transition: all 0.3s;

    &:hover {
      border: 1px solid #bce7e9;
      transition: all 0.3s;
    }
  }

  &__option-selected {
    cursor: pointer;
    border: 2px solid var(--Gradient, #42d1d6);
    margin: 1px;

    &.Withdrawn {
      border: none;
    }
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 17px 24px;
  }

  &__row-title {
    color: var(--Blue-100, #1f44dd);
    font-size: 14px;
    font-weight: 500;
    padding: 16px 24px 0 24px;
  }
  &__row-expired {
    display: inline-block;
    color: $red-500;
    background-color: $red-100;
    border-radius: 28px;
    font-size: 14px;
    font-weight: 500;
    margin-top: 14px;
    padding: 2px 12px;
  }
  &__row-declined {
    display: inline-block;
    color: white;
    background-color: #e31b54;
    border-radius: 28px;
    font-size: 14px;
    font-weight: 500;
    margin-top: 14px;
    padding: 2px 12px;
  }
  &__row-pending {
    display: inline-block;
    color: #6938ef;
    background-color: #d9d6fe;
    border-radius: 28px;
    font-size: 14px;
    font-weight: 500;
    margin-top: 14px;
    padding: 2px 12px;
  }
  &__promo-text {
    color: var(--Blue-100, #1f44dd);
    font-size: 12px;
    font-weight: 400;
    padding: 16px 24px 0 24px;
  }

  &__wrapper {
    width: 50%;
  }

  &__name {
    color: var(--gray-500, #67666e);
    font-size: 12px;
    font-weight: 400;
    display: flex;
    align-items: center;
  }

  &__value {
    color: var(--gray-800, #1a1a26);
    font-size: 16px;
    font-weight: 400;

    &.bold {
      font-weight: 600;
    }
  }
}
