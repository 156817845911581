@import "src/variables";
.ticket-table {
  width: 100%;
  margin-bottom: 32px;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    padding: 0 12px;
    &__item {display: flex;
      font-size: 12px;
      font-weight: 500;
      color: $gray-500;
      min-width: 150px;
      &.short {
        min-width: 50px;
      }
      &.middle {
        min-width: 100px;
      }
    }
  }
  &__row {
    padding: 6px 12px;
    background-color: $gray-0;
    border-radius: 6px;
    margin-top: 8px;
    box-shadow: 0 4px 8px -2px rgba(16, 24, 40, 0.1);
    font-weight: 400;
    font-size: 12px;
    color: $gray-900;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    &:hover {
      background-color: #E2F1FF;
      .offerMade {
        border: 1px solid #5925DC;
        padding: 1px 11px;
      }
      .noOffer {
        border: 1px solid #EE071B;
        padding: 1px 11px;

      }
      .Awaiting {
        border: 1px solid #175CD3;
        padding: 1px 11px;
      }
      .Awaiting_payment {
        border: 1px solid #C11574;
        padding: 1px 11px;
      }
      .Approved {
        border: 1px solid #027A48;
        padding: 1px 11px;
      }
      .Draft {
        border: 1px solid #344054;
        padding: 1px 11px;
      }
    }
    &__item {
      min-width: 150px;
      &.amount {
        font-weight: 500;
      }
      &.short {
        min-width: 50px;
      }
      &.middle {
        min-width: 100px;
      }
      &.blue {
        color: $blue-100;
      }
    }
  }
  &__empty-continer {
    margin-top: 80px;
  }
  &__status {
    display: inline-flex;
    font-size: 10px;
    font-weight: 500;
    border-radius: 16px;
    padding: 2px 12px;
    &.offerMade {
      background: var(--system-green-20, #EBE9FE);
      color: var(--system-green-100, #5925DC);
    }
    &.noOffer {
      background: var(--system-green-20, #FFE8EA);
      color: var(--system-green-100, #EE071B);
    }
    &.Awaiting {
      background: var(--system-green-20, #D1E9FF);
      color: var(--system-green-100, #175CD3);
    }
    &.Awaiting_payment {
      background: var(--system-green-20, #FCE7F6);
      color: var(--system-green-100, #C11574);
    }
    &.Approved {
      background: var(--system-green-20, #D1FADF);
      color: var(--system-green-100, #027A48);
    }
    &.Draft {
      background: var(--system-green-20, #E4E7EC);
      color: var(--system-green-100, #344054);
    }
  }
}
