@import 'src/variables';

.lenders-funnel-table-container {
  border-radius: 0 0 10px 10px;
  display: flex;
  flex-direction: column;
  border: 1px solid #F2F2F3;
  border-top: none;
  height: 145px;
  overflow: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #D3E0EC;
  }
  &::-webkit-scrollbar-track {
    height: 145px!important;
    background-color: #FFFFFF;
  }
}

.lenders-funnel-table-header {
  position: sticky;
  color: $gray-800;
  font-size: 10px;
  display: flex;
  background-color: #F9F9F9;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border: 1px solid #F2F2F3;
  border-bottom: none;
  &__column {
    border-right: 1px solid #F2F2F3;
    padding: 7px 10px;
    line-height: 14px;
    flex: 1;
    &:last-child {
      border-right:none;
    }
  }
}

.lenders-funnel-table-header.scroll {
  padding-right: 2px;
}

.lenders-funnel-table-row.scroll {
  &:last-child {
    border-bottom: none;
  }
}

.lenders-funnel-table-row {
  //border-radius: 0 0 10px 10px;
  display: flex;
  &:last-child {
    border-bottom: 1px solid #F2F2F3;
  }
  &__item {
    border-right: 1px solid #F2F2F3;
    border-top: 1px solid #F2F2F3;
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 4px 10px;
    font-size: 10px;
    color: $gray-700;
    &:last-child {
      border-right: none;
    }
  }
}
.no-landers {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  &__title {
    margin-top: 4px;
    font-size: 14px;
    line-height: 21px;
    font-weight: 600;
    color: $gray-800
  }
  &__text {
    line-height: 15px;
    font-size: 10px;
    color: $gray-400;
  }
}