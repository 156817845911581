.admin-info {
  padding: 24px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 2px 2px 8px 0px rgba(13, 21, 55, 0.12);
  font-family: "Poppins", sans-serif;
  width: 100%;
}

.prolile-contact-item {
  margin-bottom: 45px;
}

.contact-email-label {
  color: #01010e;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  padding: 0px 0 3px 0;
}

.profile-contact-email {
  margin-top: 6px;
  color: #1a1a26;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
}
