@import 'src/variables';

.table-wrapper-card {
  background: white;
  padding: 24px 16px;
  margin: 12px 0 0 0;
  border-radius: 8px 8px 0 0;
  border: 1px solid var(--neutral-300, #f1f1f1);

  .ant-table-content {
    overflow-x: scroll;
  }
}

.header-item {
  display: flex;
  align-items: center;
  color: var(--gray-800, #1a1a26);
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 11px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
  text-wrap: nowrap;
  padding: 0 20px 0 5px;
}

.table-status {
  justify-content: space-between;
}
.table-error {
  background-color: #eecfd2;
}

.header-image {
  width: 15px;
  margin: 0 0 -3px 0;
  cursor: pointer;
  transition: all 0.3s;

  &.active {
    transform: rotate(180deg);
    transition: all 0.3s;
  }
}

.table-item {
  display: flex;
  align-items: center;
  padding: 0 20px 0 5px;
  border-bottom: 1px solid #f2f2f3;
  border-right: 1px solid #f2f2f3;
  border-left: 1px solid #f2f2f3;
  height: 100%;
}

.table-item-footer {
  display: flex;
  align-items: center;
  padding: 10px 20px 10px 5px;
  height: 100%;
  color: rgb(40, 39, 39);
  background: var(--Grey-25, #f9f9f9);
  font-weight: 600;
}

.table-text {
  color: var(--gray-700, #34343e);
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 11px;
  font-style: normal;
  white-space: nowrap;
  font-weight: 400;
  line-height: normal;
  overflow-x: hidden;
  text-overflow: ellipsis;

  &.blue {
    color: var(--primary-100, #1f44dd);
    font-weight: 500;
  }

  &.status {
    height: 14px;
    overflow: hidden;
    border-radius: 28px;
    font-family: 'Poppins', sans-serif;
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    padding: 8px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.offerMade {
    background: var(--system-green-20, #e5d3f3);
    color: var(--system-green-100, #7c24c2);
  }
  &.Pending {
    background: var(--system-green-20, #e5d3f3);
    color: var(--system-green-100, #7c24c2);
    width: 60px;
  }

  &.noOffer {
    background: var(--system-green-20, #eecfd2);
    color: var(--system-green-100, #a80e1c);
  }
  &.Awaiting {
    background: var(--system-green-20, #d2daf8);
    color: var(--system-green-100, #1f44dd);
  }
  &.Withdrawn {
    background: var(--system-green-20, #d2daf8);
    color: var(--system-green-100, #1f44dd);
  }
  &.Approved {
    background: var(--system-green-20, #cce3d6);
    color: var(--system-green-100, #017130);
  }
  &.Draft {
    background: var(--system-green-20, #f5e2d2);
    color: var(--system-green-100, #8d4408);
  }
}

.table-text-acc-types {
  color: var(--gray-700, #34343e);
  text-align: start;
  font-family: 'Poppins', sans-serif;
  font-size: 11px;
  font-style: normal;
  white-space: nowrap;
  font-weight: 400;
  line-height: normal;
  height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 5px;
}

.table-logo {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0 8px 0 0;
}

.ant-table-cell {
  cursor: pointer;
  position: relative;
  height: 25px;
  padding: 0 !important;
  max-width: 50px;
}

.ant-table-cell-row-hover {
  background-color: #f4fbff !important;
}

.no-pointer .ant-table-cell {
  cursor: default;
}

.pagination {
  padding: 8px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__select {
    border: 1px solid #f4fbff;
    margin: 0 8px 0 0;

    .ant-select-selector {
      width: 57px !important;
      height: 28px !important;
    }
  }

  &__block {
    display: flex;
    align-items: center;
    color: var(--gray-800, #1a1a26);
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }

  &__text {
    border-right: 1px solid #e2e2e2;
    padding: 0 15px 0 0;
    font-size: 12px;

    &:last-child {
      border-right: none;
      padding: 0 0 0 15px;
    }
  }

  &__btn {
    border-radius: 6px;
    border: 1px solid var(--neutral-300, #f1f1f1);
    background: #fff;
    padding: 5px 16px;
    cursor: pointer;
    transition: all 0.3s;
    font-size: 12px;

    &:hover {
      background: #f1f1f1;
      transition: all 0.3s;
    }
  }

  &__arrow-left {
    border-radius: 6px;
    border: 1px solid var(--neutral-300, #f1f1f1);
    background: #fff;
    padding: 15px 8px;
    transform: rotate(90deg);
    margin: 0 24px 0 8px;
    cursor: pointer;
    transition: all 0.3s;

    &.disable {
      background: #f1f1f1;
      cursor: context-menu;
    }

    &:hover {
      background: #f1f1f1;
      transition: all 0.3s;
    }
  }
  &__arrow-right {
    border-radius: 6px;
    border: 1px solid var(--neutral-300, #f1f1f1);
    background: #fff;
    padding: 15px 8px;
    transform: rotate(-90deg);
    margin: 0 8px 0 24px;
    cursor: pointer;
    transition: all 0.3s;

    &.disable {
      background: #f1f1f1;
      cursor: context-menu;
    }

    &:hover {
      background: #f1f1f1;
      transition: all 0.3s;
    }
  }
}

.ant-table-content {
  border-radius: 0 0 8px 8px;
  width: 100%;
  overflow-y: auto;
}

.no-data-found {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 0;

  &__text {
    color: var(--gray-800, #1a1a26);
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
  }

  &__subtext {
    color: #808086;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    width: 250px;
    margin: 8px 0 0 0;
  }
}

.action {
  position: absolute;
  top: 23px;
  z-index: 2;
  left: -63px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 2px 2px 8px 0 rgba(13, 21, 55, 0.12);
  padding: 8px;

  &__item {
    display: flex;
    align-items: center;
    color: var(--gray-400, #808086);
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    min-width: 100px;
    height: 25px;
    padding: 3px 5px 3px 3px;
    cursor: pointer;

    svg {
      &:hover {
        stroke: #34343e;
      }
    }

    &:hover {
      border-radius: 8px;
      background: #f2f2f3;
      color: var(--gray-700, #34343e);
    }
  }
}

.ant-tooltip {
  position: absolute;
  background-color: black;
  color: #fff;
  border-radius: 4px;
  z-index: 55;
  font-size: 11px;
}
.table-dropdown-text {
  color: #808086;
}

.ant-tooltip-inner {
  padding: 7px !important;
}

.table-totals {
  border-bottom: 1px solid #f2f2f3;
  background: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 10px 0;
  padding: 10px 0;

  &__item {
    font-size: 11px;
    text-align: start;
    padding: 0 10px;
  }
}

.ant-table-footer {
  padding: 0 !important;
}

.footer-item {
  background-color: grey;
}

.DiscountError {
  .ant-table-wrapper {
    border: 1px solid var(--Error, #da4c53);
    border-radius: 3px;
  }
}

.DiscountFeeTable {
  .ant-table-content {
    overflow-y: scroll;
  }
  .ant-table-thead {
    height: 41px;
    background: #f1f1f6;

    .ant-table-cell {
      background: #f1f1f6;

      &:first-child {
        border-right: 1px solid #dcdcdf;
      }

      .header-item {
        color: var(--Gray-800, #1a1a26);
        text-align: center;
        font-size: 14px !important;
        font-weight: 400 !important;
        padding: 0 0 0 11px;
      }
    }
  }

  .ant-table-tbody {
    .ant-table-cell {
      .table-item {
        .table-text {
          height: 41px;
          color: var(--Gray-800, #1a1a26);
          font-size: 14px !important;
          font-weight: 400 !important;
          padding: 11px 0 0 6px;
          width: 500px;
          overflow: hidden;
          text-overflow: ellipsis;
          text-wrap: nowrap;
        }
      }
    }
  }
}
