.partner-info {
  padding: 24px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 2px 2px 8px 0 rgba(13, 21, 55, 0.12);
  font-family: 'Poppins', sans-serif;
  width: 100%;

  &__info-text {
    color: rgb(57, 57, 57);
    margin: 0 0 10px 0;
    font-size: 14px;
    display: flex;
    align-items: center;

    img {
      margin: 0 7px 2px 0;
    }
  }

  &__sub-row {
    display: flex;
    align-items: flex-start !important;
    justify-content: space-between;
    margin: 0 0 0 0;
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 0 0;
    .avatar-uploader {
      width: 148px;
      height: 148px;
    }

    .default-avatar {
      position: absolute;
      top: 0;
      z-index: 1;
      min-width: 120px;
      min-height: 120px;
      max-width: 120px;
      max-height: 120px;
      object-fit: cover;
      border-radius: 8px;

      &__wrapper {
        width: 120px;
        height: 120px;
        position: absolute;
        top: 0;
        z-index: 2;
        background: black;
        opacity: 0.3;
      }

      &__hover {
        width: 120px;
        height: 120px;
        position: absolute;
        top: 0;
        z-index: 3;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: var(--gray-0, #fff);
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }

      &__edit {
        border-radius: 8px;
        border: 1px solid #fff;
        padding: 4px 19px;
        margin: 0 0 4px 0;
        cursor: pointer;
      }

      &__delete {
        margin: 4px 0 0 0;
        cursor: pointer;
      }
    }

    .default-avatar-app-logo {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      width: 432px;
      height: 108px;
      object-fit: contain;
      border-radius: 8px;

      &__wrapper {
        width: 432px;
        height: 108px;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
        background: black;
        opacity: 0.3;
      }

      &__hover {
        width: 432px;
        height: 108px;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 3;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: var(--gray-0, #fff);
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }

      &__edit {
        border-radius: 8px;
        border: 1px solid #fff;
        padding: 4px 19px;
        margin: 0 0 4px 0;
        cursor: pointer;
      }

      &__delete {
        margin: 4px 0 0 0;
        cursor: pointer;
      }
    }
  }

  &__partner-id {
    color: var(--gray-300, #b3b3b7);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin: -20px 0 24px 0;
  }

  &__id {
    color: var(--gray-300, #b3b3b7);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin: 0 0 8px 0;
  }
}
