@import "src/variables";
.navigation {
  height: 100vh;
  position: sticky;
  top: 0;
  width: 264px;
  background: white;
  padding: 32px;
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__main {
    padding: 15px 0 0 0;
    position: relative;
  }

  &__logo {
    padding: 0 5px 30px 5px;
    height: 66px;
    width: 140px;
    object-fit: contain;
  }
  &__logout {
    display: flex;
    justify-content: space-between;
    width: 200px;
    align-items: center;
    color: #475467;
    font-size: 14px;
    font-weight: 500;
  }
  &__logout-img {
    cursor: pointer;
  }

  &__image {
    margin: 0 8px 0 0;
  }

  &__item {
    margin-top: 8px;
    width: 100%;
    border-radius: 6px;
    padding: 12px 12px;
    display: flex;
    align-items: center;
    color: #808086;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      background: $gray-50;
      transition: all 0.2s;
      color: $gray-700;
    }

    &.active {
      background: var(--primary-100, #1f44dd);
      color: #fff;
      transition: all 0.2s;

      .navigation__image {
        svg {
          fill: #808086;
        }
      }
    }
  }
}
