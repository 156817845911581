.view-member {
  display: flex;
  border-radius: 12px;
  background: #fff;
  box-shadow: 2px 2px 8px 0 rgba(13, 21, 55, 0.12);
  font-family: 'Poppins', sans-serif;

  .block-main-acc {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .block {
    width: 50%;
    padding: 24px;
    position: relative;

    &:first-child {
      border-right: 1px solid #e6e6e7;
    }

    &__admin {
      &:first-child {
        border-right: none;
      }
    }

    &__top {
      min-height: 300px;
    }

    &__main_account {
      display: flex;
      align-items: center;

      &-image {
        margin: 0 10px 0 0;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
      }

      &-column {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      &-id {
        color: #b3b3b7;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }

    &__other {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__logo-wrapper {
      display: flex;
      align-items: center;
      margin: 0 0 24px 0;
    }

    &__logo {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      object-fit: cover;
      margin: 0 16px 0 0;
    }

    &__add-info {
      height: 107px;
    }

    &__application-link {
      padding: 12px 8px;
      border: 1px solid #e6e6e7;
      max-height: 50px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__application-logo {
      object-fit: contain;
      min-width: 516px;
      max-width: 516px;
      min-height: 129px;
      max-height: 129px;
      @media screen and (max-width: 1420px) {
        min-width: 361px;
        max-width: 361px;
        min-height: 90px;
        max-height: 90px;
      }
    }

    &__name {
      color: var(--gray-800, #1a1a26);
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
    }

    &__id {
      color: #808086;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;

      &-blue {
        color: var(--primary-100, #1f44dd);
      }
    }

    &__title {
      color: var(--neutral-800, #1f2125);
      font-size: 16px;
      font-weight: 600;
    }

    &__item {
      margin: 17px 0 0 0;
      width: 100%;

      &.small {
        width: 48%;
      }
    }

    &__item-title {
      color: var(--gray-800, #5b5b69);
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      margin: 0 0 4px 0 !important;
    }

    &__item-value {
      border-radius: 8px;
      color: var(--gray-800, #1a1a26);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      min-height: 20px;
    }

    &__item-value-prod {
      border-radius: 8px;
      color: var(--gray-800, #1a1a26);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      margin: 0 0 15px 0;
    }

    &__row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    &__common-info {
      border-bottom: 1px solid #e6e6e7;
      padding: 16px 0 !important;
    }

    &__edit-wrapper {
      position: absolute;
      top: 23px;
      right: 24px;
      padding: 10px;
      transition: all 0.3s;
      border-radius: 50%;
      cursor: pointer;

      &:hover {
        background: #f2f2f3;
        transition: all 0.3s;
      }
    }

    &__item-row {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin: 16px 0;
      font-weight: 500;

      &-title {
        color: var(--grey-600, #4d4d56);
        font-size: 14px;
        line-height: 150%;
        min-width: 120px;
      }

      &-value {
        color: var(--gray-800, #1a1a26);
        font-size: 14px;
        line-height: normal;
      }
    }

    &__btn-link {
      width: 32%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__btn {
      width: 32%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 38px 0 0 0;

      &-image {
        margin: 0 5px 0 0;
      }
    }

    &__link {
      border-radius: 8px;
      border: 1px solid #e6e6e7;
      background: var(--base-white, #fff);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 16px;
      cursor: pointer;
    }

    &__link-text {
      color: var(--grey-400, #808086);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin: 12px 0 0 0;
    }

    &__pause-btn {
      height: 45px;
      width: 200px;
      margin: 40px 0 0 0;
    }
  }
}

.link {
  height: 0;
}
