.CustomerRecord {
  padding: 24px;
  width: 100%;

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__sub-block {
    flex: 1;
    box-sizing: border-box;
  }

  &__left-side {
    border-right: 1px solid #e6e6e7;
    padding-right: 24px;
  }

  &__right-side {
    padding-left: 24px;
  }

  &__user-name {
    color: #1a1a26;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 600;
  }

  &__user-id-container {
    margin-top: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__user-id-label {
    color: #808086;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 500;
  }

  &__user-id {
    color: #1f44dd;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 500;
  }

  &__user-additional-info {
    border-bottom: 1px solid #e6e6e7;
    padding: 24px 0px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__label {
    color: #4d4d56;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-weight: 500;
  }

  &__additional-info {
    margin-top: 8px;
    color: #1a1a26;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 500;
  }

  &__user-info-item-wrapper {
    min-width: 246px;
  }

  &__info-item {
    display: flex;
    align-items: center;
    gap: 24px;
  }

  &__contact-info {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__header-label {
    color: #1a1a26;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 600;
  }

  &__date-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__date-label {
    color: #4d4d56;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 500;
  }

  &__date {
    color: #4d4d56;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 500;
  }

  &__table {
    margin-top: 24px;
  }

  &__table-label {
    color: #1a1a26;
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-weight: 600;
  }

  &__bank-label {
    margin-top: 24px;
    margin-bottom: 12px;
    display: flex;
    color: #1a1a26;
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-weight: 600;
  }
  &__bank-logo {
    width: 28px;
    height: 28px;
    margin: 4px 16px 4px 0;
    border-radius: 50%;
    object-fit: cover;
  }

  &__bank-text {
    font-size: 14px;
    color: #4d4d56;
    margin-bottom: 24px;
  }

  &__refresh-btn {
    margin-left: 64px;
  }

  &__list {
    margin-top: 12px;
    border-radius: 12px;
    background: #fff;
    box-shadow: 2px 2px 8px 0px rgba(13, 21, 55, 0.12);
    font-family: 'Poppins', sans-serif;
  }
}
