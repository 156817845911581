.codeModal {
  width: 800px !important;
  .ant-modal-content {
    padding: 0 !important;

    @media (max-width: 770px) {
      padding: 40px !important;
    }
  }

  &__container {
    padding: 33px 44px;

    @media (max-width: 770px) {
      padding: 0;
    }
  }

  &__title {
    font-family: 'Poppins', sans-serif;
    color: var(--gray-700, #34343e);
    font-size: 32px;
    font-weight: 600;

    @media (max-width: 630px) {
      font-size: 28px;
    }
  }

  &__subtext {
    color: var(--gray-600, #4d4d56);
    font-size: 16px;
    font-weight: 400;
    margin: 24px 0;

    @media (max-width: 630px) {
      font-size: 14px;
    }
    @media (max-width: 580px) {
      font-size: 12px;
    }
    @media (max-width: 500px) {
      width: 245px;
    }
  }

  .DigitCodePage {
    display: block !important;

    .DigitCodePage__otp-field {
      .DigitCodePage__otp-input-input {
        margin: 0 10px;
        width: 100px;
        height: 100px;
        font-size: 32px;
        padding: 10px;
        text-align: center;
        border-radius: 5px;
        border: 1px solid #e6e6e7;
        background: #fff;
        font-weight: bold;
        outline: none;
        transition: all 0.1s;
      }
      .DigitCodePage__otp-error-input {
        border: 1px solid #a80e1c;
      }
    }
    .DigitCodePage__otp-field-error {
      left: 44px;
      width: 70%;
      text-align: start;

      @media (max-width: 800px) {
        font-size: 14px;
      }
    }
  }

  &__helperText {
    color: var(--gray-600, #4d4d56);
    font-size: 16px;
    font-weight: 400;
    margin: 44px 0;
    display: flex;
  }

  &__timer {
    color: #1f44dd;
    display: flex;
    margin: 0 0 0 5px;

    &.active {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__gradient-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
    height: 60px !important;
    background: var(
      --Gradient,
      linear-gradient(96deg, #42d1d6 9.62%, #002dff 88.97%)
    );
    border: none;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
}

.codeModalDrawer {
  font-family: 'Poppins', sans-serif;

  &__title {
    color: var(--gray-700, #34343e);
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  }

  &__subtext {
    color: var(--gray-600, #4d4d56);
    font-size: 14px;
    font-weight: 400;
    margin: 15px 0;
  }

  .DigitCodePage {
    display: block !important;

    .DigitCodePage__otp-field {
      .DigitCodePage__otp-input-input {
        width: 100px;
        height: 100px;
        font-size: 32px;
        padding: 10px;
        text-align: center;
        border-radius: 5px;
        border: 1px solid #e6e6e7;
        background: #fff;
        font-weight: bold;
        outline: none;
        transition: all 0.1s;

        @media (max-width: 600px) {
          width: 80px;
          height: 80px;
          font-size: 24px;
        }
        @media (max-width: 500px) {
          width: 60px;
          height: 60px;
        }
        @media (max-width: 400px) {
          width: 55px;
          height: 55px;
        }
      }
      .DigitCodePage__otp-error-input {
        border: 1px solid #a80e1c;
      }
    }

    .DigitCodePage__otp-block-error {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;

      .DigitCodePage__otp-field-error {
        position: relative;
        left: -7px;
        text-align: start;
      }
    }
  }

  &__helperText {
    color: var(--gray-600, #4d4d56);
    font-size: 16px;
    font-weight: 400;
    margin: 20px 0;
    display: flex;
  }

  &__timer {
    color: #1f44dd;
    display: flex;
    margin: 0 0 0 5px;

    &.active {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__gradient-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
    height: 60px !important;
    background: var(
      --Gradient,
      linear-gradient(96deg, #42d1d6 9.62%, #002dff 88.97%)
    );
    border: none;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
}
