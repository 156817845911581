.main-account-info {
  &__row {
    display: flex;
    &__between {
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;
    }
  }
}

.bank-table {
  &__title {
    font-size: 16px;
    font-weight: 500;
    &__view {
      margin-bottom: 24px;
    }
  }
}

.button {
  &__plus {
    display: flex;
    align-items: center;
    height: 48px;
    &__img {
      margin-right: 8px;
    }
  }
}