.title-recepient-info {
  margin-top: 20px !important;
}

.lender-info {
  margin-top: 12px !important;
}

.lender-info-border {
  border-bottom: 1px solid #e6e6e7;
  padding-bottom: 24px;
}

.lender {
  &__pause-btn {
    width: 200px;
  }
}
