.members {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;

  &__subtitle {
    color: var(--Neutral-800, #1f2125);
    font-size: 14px;
    font-weight: 500;
    margin: 0 0 16px 0;

    &.with-border {
      border-top: 1px solid #e6e6e7;
      padding: 20px 0 0 0;
    }
  }

  &__from-to {
    color: #b3b3b7;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    height: 30px;
    margin: 9px 8px 0px 8px;
  }

  &__toprow {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__filters-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__openFilters {
    color: var(--primary-dark, #011627);
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 600;
    border-radius: 8px;
    border: 1px solid #e4e4e4;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 20px;
    margin: -23px 0 8px 2px;
    cursor: pointer;
  }

  &__newfilters {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
  }

  &__filters {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .ant-select-selection-placeholder {
      margin: 1px 0 0 0 !important;
      font-size: 14px;
      color: var(--Neutral-600, #b3b3b7);
      font-family: 'Poppins', sans-serif;
      font-weight: 200;
      line-height: 24px;
    }
  }

  .topfilters {
    width: 100%;
    height: 80px;
    border-radius: 0 0 8px 8px;
    transition: all 0.3s;
    opacity: 1;
    visibility: visible;

    &.inactive {
      height: 0;
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s;
    }

    &__container {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__item {
    }
  }

  &__row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    &:nth-child(2) {
      margin: 12px 0 0 0;
    }
  }

  &__title {
    color: var(--grey-800, #1a1a26);
    font-size: 32px;
  }
  &__add {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    border-radius: 8px;
    background: var(--primary, #1f44dd);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    width: 150px;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      background: #405ee0;
      transition: all 0.3s;
    }

    &-image {
      margin: 0 8px 0 0;
    }
  }

  &__item {
    width: 49%;
    border-radius: 8px;
    border: 1px solid var(--neutral-300, #f1f1f1);
    background: var(--neutral-100, #fff);
    padding: 16px;
  }

  &__select {
    width: 23%;
  }

  &__table {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 16px 24px;
    border-radius: 8px;
    background: white;
    margin: 24px 0 0 0;
    &.transparent {
      background-color: transparent;
    }
  }
}

.export-btn {
  position: absolute;
  right: 0;
  top: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 6px 14px;
  border: 1px solid var(--gray-50, #f2f2f3);
  cursor: pointer;

  &__image {
    margin: 0 5px 0 0;
  }
}
