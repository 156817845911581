.main-layout {
  display: flex;
}

.ant-layout {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #f7f7f7;
}
