.fico {
  margin: 0 0 30px 0;
  position: relative;
  &__title {
    color: var(--Neutral-800, #1f2125);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0 0 8px 0;
  }

  &__action-wrapper {
    position: relative;
  }

  &__action {
    border-radius: 8px;
    box-shadow: 2px 2px 8px 0 rgba(13, 21, 55, 0.12);
    height: 85px;
    width: 85px;
    position: absolute;
    top: 12px;
    right: 53px;
    background-color: white;
    z-index: 3;
    padding: 12px 8px;

    &-row {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      padding: 5px 3px;
      color: var(--Gray-400, #808086);
      font-size: 12px;
      font-weight: 400;
      cursor: pointer;

      &:hover {
        background-color: #f1f1f6;
        border-radius: 6px;
      }
    }

    &-delete {
      margin: 0 7px 0 0;
    }
  }

  &__header {
    display: flex;
    width: 100%;
    border-radius: 8px 8px 0 0;
    background-color: #f1f1f6;
  }

  &__header-item {
    border-right: 1px solid #dcdcdf;
    padding: 10px;
    width: 50%;

    &:last-child {
      border-right: none;
    }
  }
  &__wrapper {
    display: flex;
    border: 1px solid #f1f1f6;

    &:last-child {
      border-radius: 0 0 8px 8px;
    }
  }

  &__item {
    position: relative;
    width: 50%;
    color: var(--Gray-700, #34343e);
    font-size: 14px;
    font-weight: 400;
    padding: 10px;
    border-right: 1px solid #f1f1f6;

    &:last-child {
      border: none;
    }
  }

  &__icon {
    position: absolute;
    top: 9px;
    right: 12px;
    cursor: pointer;
    padding: 4px 5px;

    &:hover {
      background-color: rgb(222, 220, 220);
      border-radius: 50%;
      padding: 4px 5px;
    }
  }

  &__addrow {
    color: #67666e;
    font-size: 14px;
    font-weight: 600;
    text-align: end;
    padding: 12px 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    img {
      width: 20px;
      margin: -3px 8px 0 0;
    }
  }
}

.add-fees-modal {
  .ant-modal-content {
    width: 600px;
  }
}

.add-item-modal {
  width: 470px !important;

  .list {
    &__nodata {
      color: var(--Gray-300, #b3b3b7);
      font-size: 12px;
      font-weight: 400;
    }

    &__item-loan {
      max-height: 0;
      width: 100%;
      background: #e5ebfb;
      padding: 0 16px;
      margin: 0 0 1px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      overflow: hidden;
      transition: all 0.3s ease;

      &.active {
        max-height: 300px;
        transition: all 0.3s ease;
        padding: 8px 16px;
      }
    }

    &__item {
      width: 100%;
      background: #e5ebfb;
      padding: 8px 16px;
      margin: 0 0 1px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:first-child {
        border-radius: 8px 8px 0 0;
      }

      &:last-child {
        border-radius: 0 0 8px 8px;
      }
    }

    &__item-container {
      background: white;
      padding: 6px;
      border-radius: 8px;
      width: 100%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

    &__item-product {
      border-radius: 1000px;
      background: var(--Neutral-200, #f7f7f7);
      padding: 4px 12px;
      color: var(--Neutral-800, #1f2125);
      font-size: 12px;
      font-weight: 500;
      margin: 0 4px 4px 0;
      cursor: pointer;
      transition: all 0.3s;

      &.active {
        background: var(--Accent-500, #3a5be0);
        color: var(--Neutral-100, #fff);
        transition: all 0.3s;
      }
    }

    &__arrow {
      cursor: pointer;
      transition: all 0.3s;
      &.active {
        transform: rotate(180deg);
        transition: all 0.3s;
      }
    }
  }

  &__title {
    color: var(--Gray-900, #01010e);
    font-size: 18px;
    font-weight: 600;
    padding: 0 0 15px 0;
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__item {
    width: 48%;
    .ant-form-item-explain {
      margin: -19px 0 0 0;
    }
  }
}
.distribution-tooltip-item {
  background-color: #51515B;
  width: 216px;
  border-radius: 2px;
  padding: 4px;
  margin-bottom: 4px;
}
