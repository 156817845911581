@import "src/variables";
.settings-tab {
  &__wrapper {
    display: flex;
  }
  &__btn {
    background-color: transparent;
    border: none;
    box-shadow: none;
    color: #667085;
    &:hover {
      color: $blue-100!important;
    }
  }
}
.settings-tab__btn.active {
  font-family: 'Poppins', sans-serif;
  color: $blue-100;
  background-color: rgba(31, 68, 221, 0.08);
}