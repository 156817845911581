@import "variables";
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
}

code {
  font-family: "Poppins", sans-serif;
}

.ant-select-clear {
  width: 23px !important;
  top: 17px !important;
}

img {
  margin: 0;
}
/* Указываем box sizing */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Убираем внутренние отступы */
ul[class],
ol[class] {
  padding: 0;
}

/* Убираем внешние отступы */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* Выставляем основные настройки по-умолчанию для body */
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* Удаляем стандартную стилизацию для всех ul и il, у которых есть атрибут class*/
ul[class],
ol[class] {
  list-style: none;
}

/* Элементы a, у которых нет класса, сбрасываем до дефолтных стилей */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Упрощаем работу с изображениями */
img {
  max-width: 100%;
  display: block;
}

/* Указываем понятную периодичность в потоке данных у article*/
article > * + * {
  margin-top: 1em;
}

/* Наследуем шрифты для инпутов и кнопок */
input,
button,
textarea,
select {
  font: inherit;
}

/* Удаляем все анимации и переходы для людей, которые предпочитай их не использовать */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

/* Style the scrollbar */
::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

/* Style the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: #808080;
  border-radius: 6px;
}

/* Style the scrollbar track */
::-webkit-scrollbar-track {
  background-color: #f0f0f0;
}

/* Style the scrollbar buttons */
::-webkit-scrollbar-button {
  background-color: #cccccc;
}

/* Style the scrollbar corner */
::-webkit-scrollbar-corner {
  background-color: #f0f0f0;
}

.input-wrapper {
  width: 100%;
  margin: 0 0 27px 0;
}

.item {
  width: 48%;

  &.routing {
    width: 85%;
  }
}

.w-100 {
  width: 100% !important;
}
.w-50 {
  width: 50% !important;
}

.option-select {
  display: flex;
  align-items: center;
  font-family: "Poppins", sans-serif;

  &__image {
    width: 18px;
    height: 18px;
    object-fit: cover;
    border-radius: 50%;
    margin: 0 4px 5px 0;
  }

  &__id {
    color: #b3b3b7;
    font-family: "Poppins", sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: -11px 0 0 0;
  }
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar-wrap {
  width: 148px;
  height: 148px;
}

.procent {
  color: #1a1a26;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.spinner-wrapper {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: black;
  z-index: 2;
  opacity: 0.3;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-select-selector {
  border: none !important;
  height: 30px !important;
  font-size: 15px;
}

.ant-select-selection-search-input {
  height: 100% !important;
}

.ant-select-selection-placeholder {
  margin: 7px 0 0 0 !important;
  font-size: 14px;
  color: var(--Neutral-600, #b3b3b7);
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  line-height: 24px;
}

.ant-input::placeholder {
  margin: 0 0 0 5px !important;
  font-size: 14px;
  color: var(--Neutral-600, #b3b3b7);
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  line-height: 24px;
}

.wrapper-search-input {
  height: 15px !important;
  width: 100%;
}

.ant-space {
  .ant-space-item {
    width: 100%;
  }
}

.search-input {
  width: 100%;

  .ant-input {
    background-color: white;
  }
  border: none !important;
  padding: 5px 0 !important;
  box-shadow: none !important;

  &:focus {
    box-shadow: none !important;
    border: none !important;
  }
}

.ant-upload-list-item-name {
  display: none;
}

.ant-upload-list-item {
  display: none !important;
}

.ant-checkbox {
  .ant-checkbox-inner {
    width: 18px !important;
    height: 18px !important;
  }
}
.info-wrap__checkbox.ant-checkbox-checked {
  &.ant-checkbox-inner {
    background: $blue-100 !important;
  }
}

.ant-message-notice-content {
  background-color: var(--gray-600, #101828) !important;
  display: flex;
  align-items: center;
  color: white;
}

.anticon {
  margin: 0 0 0 0;
}

.ant-modal-close {
  &:hover {
    background: transparent !important;
  }
}

.ant-message-success {
  background-color: var(--gray-600, #101828);
  color: var(--gray-0, #fff);
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  display: flex;
  align-items: center;
}

.ant-input-status-error {
  border-color: #da4c53 !important;
}

.alert {
  position: absolute;
  top: 28px;
  right: 11px;
}

.ant-input-affix-wrapper-status-error {
  border-color: #da4c53 !important;
}

.ant-form-item {
  margin-bottom: 0 !important;
}

.ant-form-item-explain-error {
  color: #da4c53 !important;
}

.ant-select-selection-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .option-select {
    height: 44px !important;
  }
}

.error-text {
  color: #da4c53 !important;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  line-height: 1.5714285714285714;
  font-style: normal;
  font-weight: 400;
}
.ant-form-item-explain-error {
  color: #da4c53 !important;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  line-height: 1.5714285714285714;
  font-style: normal;
  font-weight: 400;
}

.ant-form-item-control {
  width: 120px !important;
  min-height: 77px;
}

.ant-form-item-has-error {
  .ant-form-item-control-input {
    .ant-form-item-control-input-content {
      .ant-upload-wrapper {
        .ant-upload {
          .ant-upload {
            border: 1px solid #da4c53 !important;
          }
        }
      }
    }
  }
}

.ant-upload-wrapper {
  &.error {
    .ant-upload {
      .ant-upload {
        border: 1px solid #da4c53 !important;
      }
    }
  }
}

.ant-select-status-error {
  .ant-select-selector {
    border: 1px solid #da4c53 !important;
  }
}

.table-text {
  text-align: start !important;
}

.tab-btn {
  color: var(--Gray-400, #808086);
  font-size: 14px;
  font-weight: 500;
  border-radius: 8px;
  border: none;
  margin: 0 0 0 24px;
  background: transparent;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.err {
    border: 1px solid #da4c53;
  }

  &:hover {
    color: var(--Gray-400, #808086) !important;
  }

  img {
    margin: 0 0 0 8px;
  }

  &:first-child {
    margin: 0 0 0 0;
  }

  &.active {
    color: var(--Blue-100, #1f44dd);
    background: rgba(31, 68, 221, 0.08);
  }
}

.blue-btn {
  font-family: "Poppins", sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 4px;
  background: var(--primary, #1f44dd);
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  height: 32px;
  min-width: 90px;
  border: none;
  padding: 0 16px;

  &:disabled {
    background-color: $gray-300;
    color: #fff;
  }

  &.disable {
    background-color: $gray-300;
    color: #fff;
    cursor: context-menu;

    &:hover {
      background: var(--gray-100, #e6e6e7);
      color: var(--gray-400, #808086) !important;
    }
  }

  &:hover {
    color: white !important;
    background: #1a38b9 !important;
  }
}
.blue-btn-lg {
  font-family: "Poppins", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: none;
  background: var(--primary, #1f44dd);
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  height: 44px;
  min-width: 170px;

  &.disable {
    background: var(--gray-100, #e6e6e7);
    color: var(--gray-400, #808086);
    border: 1px solid var(--gray-100, transparent) !important;
    cursor: context-menu;

    &:hover {
      background: var(--gray-100, #e6e6e7);
      color: var(--gray-400, #808086) !important;
      border: 1px solid var(--gray-100, transparent) !important;
    }
  }

  &:hover {
    color: white !important;
    background: #1a38b9 !important;
  }
}

.copy-link-btn {
  font-family: "Poppins", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: #f9f9f9;
  color: var(--blue-100, #1f44dd);
  font-size: 16px;
  font-weight: 400;
  height: 45px;
  // padding: 0 7px 0 14px;
  border: 1px solid rgb(232, 229, 229);

  span {
    margin: 0 10px 0 0;
  }

  &:hover {
    color: var(--blue-100, #1f44dd) !important;
  }
}

.spinner-wrapper-regenerate {
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.grey-blue-btn {
  font-family: "Poppins", sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 8px;
  background: #f9f9f9;
  color: var(--blue-100, #1f44dd);
  font-size: 14px;
  font-weight: 400;
  height: 45px;
  min-width: 90px;
  width: 260px;
  border: none;

  &.disable {
    background: var(--gray-100, #e6e6e7);
    color: var(--gray-400, #808086);
    border: 1px solid var(--gray-100, transparent) !important;
    cursor: context-menu;

    &:hover {
      background: var(--gray-100, #e6e6e7);
      color: var(--gray-400, #808086) !important;
      border: 1px solid var(--gray-100, transparent) !important;
    }
  }

  &:hover {
    color: white !important;
    background: #405ee0;
  }
}
.gray-btn-with-border {
  font-family: "Poppins", sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 8px;
  background: transparent;
  border: 1px solid var(--gray-100, #e6e6e7);
  color: var(--gray-400, #808086);
  font-size: 14px;
  font-weight: 400;
  height: 36px;
  min-width: 90px;
  padding: 0 30px;
  margin: 0 15px 0 0;

  &.active {
    color: var(--blue-100, #1f44dd);
    border: 1px solid #1f44dd;
  }
}

.violet-btn {
  font-family: "Poppins", sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 8px;
  background: #7c24c2;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  height: 45px;
  min-width: 90px;

  &.disable {
    background: var(--gray-100, #e6e6e7);
    color: var(--gray-400, #808086);
    border: 1px solid var(--gray-100, transparent) !important;
    cursor: context-menu;

    &:hover {
      background: var(--gray-100, #e6e6e7);
      color: var(--gray-400, #808086) !important;
      border: 1px solid var(--gray-100, transparent) !important;
    }
  }

  &:hover {
    color: white !important;
    background: #9f4ce3;
  }
  &:disabled {
    background: var(--gray-100, #e6e6e7);
    color: var(--gray-400, #808086) !important;
    border: 1px solid var(--gray-100, transparent) !important;
    cursor: context-menu;
  }
}

.violet-light-btn {
  font-family: "Poppins", sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 8px;
  background: rgba(124, 36, 194, 0.1);
  color: var(--Other-Purple, #7c24c2);
  font-size: 14px;
  font-weight: 400;
  height: 45px;
  width: 90px;
  border: none;
  cursor: context-menu;

  &:hover {
    color: var(--Other-Purple, #7c24c2) !important;
    background: rgb(172, 95, 235) 4, 36, 194, 0.1;
    border: none;
  }
}

.accepted.violet-btn {
  background: #5773e5;
  border: 1px solid #5773e5;
  color: $green-100;
  &:disabled {
    color: $green-100 !important;
    background: rgba($green-100, 0.1) !important;
    border: 1px solid rgba($green-100, 0.1) !important;
  }
}

.gray-btn {
  color: var(--grey-500, #67666e);
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  background-color: transparent !important;
  border: none;
  box-shadow: none;
  //width: 90px;

  &:hover {
    color: var(--grey-500, #67666e) !important;
  }
}
.gray-btn-lg {
  color: #344054;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  background-color: transparent !important;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  box-shadow: none;
  height: 44px;
  width: 170px;

  &:hover {
    color: var(--grey-500, #67666e) !important;
    border: 1px solid #d0d5dd !important;
    background-color: $gray-50 !important;
  }
}

.red-btn {
  font-family: "Poppins", sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 4px;
  border: 1px solid var(--gray-100, transparent) !important;
  background: var(--system-red-100, #a80e1c);
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  height: 32px;
  width: 100px;

  &.disable {
    background: var(--gray-100, #e6e6e7);
    color: var(--gray-400, #808086);
    border: 1px solid var(--gray-100, transparent) !important;
    cursor: context-menu;

    &:hover {
      background: var(--gray-100, #e6e6e7);
      color: var(--gray-400, #808086) !important;
      border: 1px solid var(--gray-100, transparent) !important;
    }
  }

  &:hover {
    color: white !important;
    background: var(--system-red-100, #a1212c);
    border: 1px solid #a1212c !important;
  }
}
.red-btn-lg {
  font-family: "Poppins", sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 8px;
  background: var(--system-red-100, #d92d20);
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  height: 44px;
  width: 170px;

  &.disable {
    background: var(--gray-100, #e6e6e7);
    color: var(--gray-400, #808086);
    border: 1px solid var(--gray-100, transparent) !important;
    cursor: context-menu;

    &:hover {
      background: var(--gray-100, #e6e6e7);
      color: var(--gray-400, #808086) !important;
      border: 1px solid var(--gray-100, transparent) !important;
    }
  }

  &:hover {
    color: white !important;
    background: var(--system-red-100, #a1212c);
    border: 1px solid #a1212c !important;
  }
}

.ant-breadcrumb-link {
  color: var(--grey-300, #b3b3b7);
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  &:hover {
    background-color: inherit !important;
    color: inherit !important;
    cursor: pointer;
  }
}

.container {
  padding: 24px;
  min-height: calc(100vh - 76px);
  width: 100%;
}
.container-new {
  padding: 32px;
  min-height: calc(100vh - 76px);
  width: 100%;
}
.ant-select-item {
  padding: 3px 12px !important;
  max-height: 32px !important;
  min-height: 32px !important;

  .option-select__id {
    margin: -10px 0 0 0;
    font-size: 8px;
  }
}

.ant-select-item-option {
  height: 44px;
}

.ant-select-item-option-state {
  display: none !important;
}

.ant-select-item-option-content {
  .ant-checkbox-wrapper {
    margin: 0 10px 0 0 !important;
  }
}

.ant-select-item-option-selected {
  background-color: white !important;
  font-weight: 400 !important;
}

.ant-select-selection-item-content {
  font-size: 14px;
  .ant-checkbox-wrapper {
    display: none !important;
  }
}

.ant-picker {
  width: 100%;
}

.ant-picker-input::placeholder {
  font-size: 14px;
  color: var(--Neutral-600, #9e9ea4) !important;
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  line-height: 24px;
}

.multiSelect {
  .input-white-small {
    .ant-select-arrow {
      width: 10px;
    }
    .cancel-btn {
      width: 20px !important;
      margin: -3px 0 0 0;
      cursor: pointer;
    }
    .ant-select-selector {
      overflow: hidden;
      padding: 0 0 0 10px !important;
    }
    .ant-select-selection-item {
      height: 24px !important;
      padding: 13px;

      .option-select {
        font-size: 12px;
      }

      .ant-select-selection-item-remove {
        font-size: 10px !important;
      }
    }
  }

  .ant-select-selector {
    height: auto !important;

    .ant-select-selection-placeholder {
      margin: 0 0 0 0 !important;
      font-size: 14px;
      color: var(--Neutral-600, #b3b3b7);
      font-family: "Poppins", sans-serif;
      font-weight: 200;
      line-height: 24px;
    }
  }

  .ant-select {
    height: auto !important;
  }

  .ant-select-selector {
    padding: 8px;
  }
  .ant-select-selection-item {
    border-radius: 20px !important;
    background: var(--blue-100, #1f44dd) !important;
    color: white !important;
    height: 32px !important;
    min-width: 50px;
    padding: 8px 4px 8px 12px;

    .ant-select-selection-item-remove {
      color: white !important;
      font-size: 13px !important;
    }
  }
}

.ant-upload {
  height: 120px !important;
  border: none !important;
}

.ant-upload-drag {
  // width: 120px !important;
  background: var(--base-white, #fff) !important;
}

.breadscrumbs {
  display: flex;
  align-items: center;
  &__title {
    color: $gray-500;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    cursor: pointer;

    &-active {
      color: $blue-100;
      cursor: context-menu;
    }
  }

  &__separatop {
    padding: 0 8px;
  }
}

.timer {
  position: absolute;
  top: 35px;
  color: var(--blue-100, #1f44dd);
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

.recharts-default-legend {
  display: none;
}

.recharts-cartesian-axis-line {
  stroke: none !important;
}

.recharts-cartesian-axis-tick-line {
  display: none;
}

.recharts-line-dot {
  visibility: hidden;
}
.recharts-active-dot {
  .recharts-dot {
    //visibility: hidden;
  }
}

.recharts-cartesian-axis-tick-value {
  fill: #94a3b8;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  font-family: "Poppins", sans-serif;
  y: 200 !important;
}

.custom-tooltip {
  width: 194px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 4px 8px rgba(16, 24, 40, 0.07);
  padding: 8px;

  &__title {
    color: $gray-700;
    font-size: 12px;
    font-weight: 500;
    border-bottom: 1px solid var(--gray-200, rgb(230, 230, 234));
    padding: 0 0 8px 0;
    margin-bottom: 8px;
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__graytext {
    color: $gray-500;
    font-size: 12px;
    font-weight: 400;
  }

  &__blacktext {
    color: $gray-900;
    font-size: 12px;
    font-weight: 500;
  }
}

.xAxis {
  display: none;
}

.values {
  position: absolute;
  display: flex;
  align-items: center;
  width: 92%;
  justify-content: space-between;
  bottom: 30px;
  padding: 0 34px;
  left: 32px;
  color: $gray-500;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.ant-picker-dropdown {
  .ant-picker-presets {
    ul {
      li {
        color: var(--Gray-800, #1a1a26);
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        padding: 4px 4px 4px 12px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
      li:hover {
        border-radius: 8px !important;
        background: var(--Hover, #f7f7f7);
      }
    }
  }
}

.ant-picker-range-separator {
  background-image: url("./assets/Calendararrow.svg");
  background-repeat: no-repeat;
  line-height: 2 !important;
}

.ant-picker-separator {
  opacity: 0;
}

.ant-picker-range .ant-picker-input input::placeholder {
  font-size: 14px;
  color: var(--Neutral-600, #b3b3b7);
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  line-height: 24px;
}

.checkbox-title {
  color: #1a1a26;
  font-size: 12px;
  font-weight: 500;
}

.switcher-wrapper {
  height: 118px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: end;
  margin: 0 0 0 40px;
}
.loantype {
  .ant-select-selection-placeholder {
    margin: 0 !important;
  }
}

.add-item-modal {
  .ant-select-selection-placeholder {
    margin: 0 !important;
    color: rgb(173, 172, 172);
  }
}

.fico-select {
  .ant-form-item-explain-error {
    margin: -19px 0 0 0;
  }
}

.loanTab {
  .ant-form-item-explain-error {
    margin: -19px 0 0 0;
  }
}

.block__block__item-title {
  color: #4d4d56;
  font-size: 12px;
}

/* Стили для внешнего контейнера выпадающего списка */
.autocomplete-dropdown-container {
  position: absolute;
  z-index: 4;
  border-radius: 40px;
  width: 100%; /* Задайте нужную ширину */
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.suggestion-item {
  padding: 5px;

  &:first-child {
    margin: 2px 0 0 0;
    border-radius: 8px 8px 0 0;
  }

  &:last-child {
    border-radius: 0 0 8px 8px;
  }

  &--active {
    &:first-child {
      margin: 2px 0 0 0;
      border-radius: 8px 8px 0 0;
    }

    &:last-child {
      border-radius: 0 0 8px 8px;
    }
  }
}
.rotate-180 {
  transform: rotate(180deg);
}
.d-none {
  display: none;
}
.d-flex {
  display: flex;
}

.f-d-column {
  flex-direction: column;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.flex-wrap {
  flex-wrap: wrap;
}
.align-start {
  align-items: start;
}
.align-end {
  align-items: end;
}
.align-center {
  align-items: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-center {
  justify-content: center;
}
.justify-end {
  justify-content: end;
}
.d-inline-block {
  display: inline-block;
}
.pl-2 {
  padding-left: 4px;
}
.mr-1 {
  margin-right: 2px;
}
.mr-2 {
  margin-right: 4px;
}
.mr-3 {
  margin-right: 6px;
}
.mr-4 {
  margin-right: 8px;
}
.mr-6 {
  margin-right: 12px;
}
.mr-8 {
  margin-right: 16px;
}
.ml-3 {
  margin-left: 6px;
}
.mr-12 {
  margin-right: 24px;
}
.ml-5 {
  margin-left: 10px;
}
.ml-12 {
  margin-left: 24px;
}
.mb-3 {
  margin-bottom: 6px;
}
.mb-4 {
  margin-bottom: 8px;
}
.mb-16 {
  margin-bottom: 32px;
}
.mt-0 {
  margin-top: 0 !important;
}
.mt-1 {
  margin-top: 2px;
}
.mt-5 {
  margin-top: 10px;
}
.mt-8 {
  margin-top: 16px;
}
.mt-10 {
  margin-top: 20px;
}

.h-32 {
  height: 32px;
}
.h-40 {
  height: 40px;
}
.h-44 {
  height: 44px !important;
}
.w-auto {
  width: auto !important;
}
.cursor-pointer {
  cursor: pointer;
}

.vin-info-container {
  padding: 14px 12px;
  background-color: transparent;
  color: white;
  border: solid 1px $gray-400;
  border-radius: 8px;

  &__row-item {
    flex: 1;
  }
  &__title {
    color: $gray-400;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 21px;
  }
  &__text {
    color: $gray-800;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }
}

.btn-red-text {
  border-radius: 8px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--White, #fff);
  color: var(--Error-700, #b42318);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  width: 140px;
  height: 40px;

  &:hover {
    border: 1px solid var(--Gray-300, #b9bbbf) !important;
    color: var(--Error-700, #b42318) !important;
  }
}
