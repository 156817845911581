@import 'src/variables';

.lenders-funnel {
  border-radius: 12px;
  background: #fff;
  box-shadow: 2px 2px 8px 0 rgba(13, 21, 55, 0.12);
  font-family: 'Poppins', sans-serif;
  padding: 24px;
}
.tabs-wrapper {
  display: flex;
  margin-bottom: 44px;
  justify-content: space-between;
}

.tab-button {
  border-color: $gray-100;
  border-radius: 8px;
  color: $gray-400;
  height: 36px;
  margin-right: 8px;
  &:hover {
    border-color: $blue-100!important;
    color: $blue-100!important;
  }
}
.tab-button.active {
  border-color: $blue-100;
  color: $blue-100;
}
.synk-button {
  margin-left: 10px;
  min-width: 143px;
  background-color: $blue-100;
  border-color: $blue-100;
  color: $gray-0;
  display: flex;
  &:hover {
    border-color: $blue-100!important;
    color: $gray-0!important;
  }
}

.round-tab-container {
  background-color: $neutral-200;
  padding: 4px;
  border-radius: 1000px;
  margin-left: 120px;
  display: flex;
  flex-wrap: nowrap;
  min-width: 220px;
  height: 36px
}
.tab-button-round {
  border: none;
  color: $gray-800;
  font-size: 12px;
  border-radius: 30px;
  width: 102px;
  height: 28px;
  background-color: $neutral-200;
  box-shadow: none;
  &:hover {
    color: inherit!important;
  }
  &:first-child {
    margin-right: 8px;
  }
}
.tab-button-round.active {
  color: $gray-0;
  background-color: $blue-100;
  &:hover {
    color: $gray-0!important;
  }
}
.table-container {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  &_item {
    flex: 0 0 calc(50% - 16px);
    box-sizing: border-box;
    &__title {
      font-size: 16px;
      font-weight: bolder;
      color: $gray-800;
      margin-bottom: 16px;
    }
  }
}
.lenders-funnel-line {
  margin-top: 32px;
  margin-bottom: 24px;
  color: #F2F2F3;
  background-color: #F2F2F3;
  border: 1px solid #F2F2F3;
  border-bottom: none;
}