@import 'src/variables';
.header {
  height: 76px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  font-family: 'Poppins', sans-serif;
  &__title-container {
    margin-right: 52px;
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
  }
  &__title {
    color: $gray-900;
    font-size: 24px;
    font-weight: 500;
  }
  &__tab {
    display: flex;
    //padding-top: 8px;
    margin-left: 96px;
  }
  &__wrapper-notif {
    position: relative;
    height: 30px;
  }

  &__notif-count {
    background-image: url('../../assets/Ellipse\ 534.svg');
    width: 10px;
    height: 10px;
    position: absolute;
    top: 7px;
    right: 7px;
  }

  &__settings {
    display: flex;
    align-items: center;
  }

  &__image {
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: #F2F4F7;
      border-radius: 50%;
    }
  }

  &__search {
    margin: 0 0 -18px 0;
  }

  &__row {
    display: flex;
    align-items: center;
  }

  &__user-info {
    display: flex;
  }

  &__profile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 5px;
    min-width: 210px;
    padding: 16px 26px;
    cursor: pointer;

    &.open {
      min-width: 210px;
      border-radius: 8px 8px 0 0;
      height: 57px;
      box-shadow: 0px 3px 15px -3px rgba(13, 21, 55, 0.12);
      padding: 16px 26px;
      transition: all 0.3s;
    }
  }

  &__avatar {
    width: 52px;
  }

  &__name {
    color: #808086;
    font-size: 14px;
    font-weight: 600;
    margin: 0 14px 0 0;
  }

  &__arrow {
    transition: all 0.3s;

    &.open {
      transform: rotate(180deg);
      transition: all 0.3s;
    }
  }

  &__menu {
    position: absolute;
    top: 65px;
    height: 141px;
    width: 210px;
    z-index: 2;
    background: white;
    border-radius: 0 0 8px 8px;
    box-shadow: -1px 5px 8px -3px rgba(13, 21, 55, 0.12);
    border-top: 1px solid #f2f2f3;
    transition: 0.3s ease-in-out, border-top 0s;
    overflow: hidden;
    &.h-70 {
      height: 76px;
    }

    &.super_admin {
      height: 210px;
      transition: 0.3s ease-in-out, border-top 0s;
    }

    &.close {
      height: 0px;
      border-top: none;
      overflow: hidden;
    }
  }

  &__my-profile {
    display: flex;
    align-items: center;
    padding: 16px 11px;
    color: var(--grey-800, #1a1a26);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    cursor: pointer;
    margin: 10px;
    border-radius: 8px;

    &.active {
      background: var(--grey-50, #1f44dd);
      color: white;

      &:hover {
        background: var(--grey-50, #1f44dd);
      }
    }

    &:hover {
      background: var(--grey-50, #f2f2f3);
    }
  }

  &__item-title {
    margin: 0 0 0 15px;
  }

  &__profile-image {
    margin: 0 8px 0 0;
  }

  &__logout {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    color: var(--grey-800, #1a1a26);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    cursor: pointer;
    margin: 10px;
    border-radius: 8px;

    &:hover {
      background: var(--grey-50, #f2f2f3);
    }
  }
}

.ticket-menu {
  &__container {
    position: absolute;
    display: flex; flex-direction: column;
    top: 60px;
    right: 70px;
    background-color: white;
    width: 152px;
    height: 88px;
    border-radius: 8px;
    box-shadow: 2px 2px 8px 0px rgba(13, 21, 55, 0.12);
    z-index: 50;
  }
  &__item {
    cursor: pointer;
    display: flex;
    padding: 14px;
    line-height: 16px;
    &:first-child {
      border-radius: 8px 8px 0 0;
    }
    &:last-child {
      border-radius: 0 0 8px 8px;
    }
    &:hover {
      background-color: #F0F5FF;
      color: $blue-100;
    }

  }
  &__rhombus {
    width: 31px;
    height: 31px;
    background-color: white;
    transform: rotate(45deg);
    position: absolute;
    border-radius: 8px;
    z-index: 1;
    top: -15px;
    //right: -5px;
  }
}
.color_blue {
  color: $blue-100;
}
.settings-menu {
  &__container {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 85px;
    right: 165px;
    background-color: white;
    width: 152px;
    height: 44px;
    border-radius: 8px;
    z-index: 50;
    &::before {
      content: '';
      position: absolute;
      top: -29px;
      left: 50%;
      transform: translateX(-50%);
      //background-color: #FFFFFF;
      border-left: 31px solid transparent;
      border-right: 31px solid transparent;
      border-bottom: 31px solid #fff;
      //border-radius: 8px;
      z-index: 1;
    }
  }
  &__item {
    cursor: pointer;
    display: flex;
    padding: 14px;
    line-height: 16px;
    &:hover {
      background-color: #f7f7f7;
    }
  }
  &__rhombus {
    width: 31px;
    height: 31px;
    background-color: white;
    transform: rotate(45deg);
    position: absolute;
    border-radius: 8px;
    z-index: 1;
    top: -15px;
    //right: -5px;
  }
}
.color_blue {
  color: $blue-100;
}
