@import "src/variables";

.company-profile {
  &__container {
    font-family: 'Poppins', sans-serif;
    margin-top: 64px;
  }
  &__body {
    margin-top: 24px;
    background-color: $gray-0;
    border: 1px solid $gray-200;
    border-radius: 12px;
    height: 196px;
    box-shadow: 2px 6px 24px rgba(13, 21, 55, 0.05);
  }
  &__logo {
    width: 80px;
    height: 40px;
    object-fit: contain;
  }
  &__body-info {
    padding: 24px;
    display: flex;
  }
  &__block-product {
    width: 40%;
    margin-bottom: 8px;
  }
  &__block {
    margin-right: 64px;
    align-items: center;
    &__title {
      font-size: 12px;
      font-weight: 500;
      color: $gray-500;
    }
    &__text {
      font-size: 14px;
      font-weight: 500;
      color: $gray-900;
    }
  }
}