@import "src/variables";
.new-member {
  font-family: 'Poppins', sans-serif;

  &_header {
    display: flex;
  }
  &_tab {
    display: flex;
    margin-bottom: 24px;
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__varification-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50%;
    margin: -5px 0 0 0;
  }

  &__varified {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--blue-100, #1f44dd);
    font-size: 16px;
    font-weight: 400;
    border-radius: 8px;
    border: 1px solid var(--gray-100, #e6e6e7);
    background: #fff;
    height: 32px;
    margin: -3px 0 0 0;
  }

  &__varification-btn {
    width: 48%;
    height: 32px;
  }

  .main {
    &__tab-row {
      display: flex;
      margin: 0 0 24px 0;
    }

    &__loan-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: 0 0 0 24px;
    }

    &__add-loan {
      color: var(--Gray-400, #808086);
      font-size: 14px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      width: 105px;
      height: 32px;
      cursor: pointer;

      &.err {
        border: 1px solid #da4c53;
        border-radius: 8px;
        padding: 3px;
      }
    }
    &__container {
      display: flex;
    }
    &__title-block {
      box-sizing: border-box;
      width: 32%;

      @media (max-width: 1440px) {
        width: 32%;
      }
      @media (max-width: 1920px) {
        width: 23%;
      }
    }

    &__form-block {
      box-sizing: border-box;
      width: 68%;
      @media (max-width: 1440px) {
        width: 68%;
      }
      @media (max-width: 1920px) {
        width: 77%;
      }
    }

    &__title {
      color: #1a1a26;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
    }
    &__subtitle {
      color: var(--grey-400, #808086);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }

    &__btns {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex: 1;
      margin: 24px 0 0 0;
    }
  }
}
.subdomain-helptext {
  color: var(--grey-palette-700, #34343e);
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin: -45px 0 0 0;
}

.vin-accept-text {
  color: $blue-100;
  margin-left: 16px;
  line-height: 32px;
  cursor: pointer;
}

.vin-info-container {
  padding: 14px 12px;
  margin-top: 8px;
  background-color: transparent;
  color: white;
  border: solid 1px $gray-400;
  border-radius: 8px;
  flex: 1;
  &__row {
    display: flex;
    justify-content: space-between;
    &:first-child {
      margin-bottom: 16px;
    }
  }
  &__row-item {
    flex: 1;
  }
  &__title {
    color: $gray-400;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
  }
  &__text {
    color: $gray-800;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
}