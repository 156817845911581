.skeleton {
  padding: 25px;

  &__column {
    display: flex;
    flex-direction: column;
  }
  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0 0 20px 0;

    &.table_filters {
      margin: 0 0 40px 0;
    }

    &.funnel {
      padding: 0 10px;
      margin: 10px 0;
    }

    &.funnel:nth-child(even) {
      background-color: #f9fafb;
    }
  }

  &__row-item {
    width: 70%;
  }

  &__white-block {
    background-color: white;
    border-radius: 20px;
    padding: 20px;
  }

  &__funnel {
    width: 45%;
  }

  &__big-white-block {
    background-color: white;
    border-radius: 20px;
    padding: 30px 20px;
    min-height: 540px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__white-row {
    width: 100%;
    background-color: white;
    border-radius: 20px;
    padding: 30px 20px;
    min-height: 205px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__grey-block {
    background-color: #f2f4f7;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
  }

  &__block {
    display: flex;
    flex-direction: column;
  }

  &__item {
    background-color: rgb(255, 255, 255);
    border-radius: 8px;

    &.another-table_item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 20px;
    }

    &.another-table_item:nth-child(even) {
      background-color: #f9fafb;
    }

    &.filters {
      height: 33px;
      width: 15%;
    }

    &.top-row-item {
      height: 170px;
      width: 23%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &.large-block {
      height: 320px;
      width: 49%;
    }

    &.table_row {
      background-color: #f9f9f9;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 10px 0;
    }
  }

  &__pagination {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 15%;
  }
}

.navigation-skeleton {
  height: 400px;
  position: sticky;
  top: 0;
  width: 264px;
  padding: 32px;
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.notif-item {
  display: flex;
  flex-direction: column;
}
