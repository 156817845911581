@import "src/variables";
.submit-ticket-upload {
  margin-top: 16px;
  padding: 16px 24px;
  width: 352px;
  border: 1px solid $gray-200;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__title {
    margin-top: 12px;
    font-size: 14px;
    font-weight: 500;
    color: $blue-100;
    &.disabled {
      color: $gray-300;
    }
  }
  &__text {
    margin-top: 4px;
    color: $gray-500;
    font-size: 12px;
  }
  &__file-container {
    padding: 14px;
    margin-top: 16px;
    border: 1px solid $blue-100;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
  }
  &:hover {
    background-color: #F0F5FF;
  }
  &.disabled {
    background-color: #F9FAFB;
    cursor: not-allowed;
  }
  &__file-title {
    font-size: 12px;
    font-weight: 500;
    color: $blue-100;
    &:hover {
      color: $blue-100;
      text-decoration: underline;
    }
  }
  &__file-delete-block {
    width: 28px;
    height: 28px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: #D9E3F8;
    }
  }
}