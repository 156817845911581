.SelectedOffer {
  font-family: 'Poppins', sans-serif;
  display: flex;
  max-width: 100%;
  flex-direction: column;
  align-items: center;
  margin: 0px 24px 50px 24px;

  &__topitem {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e5ebfb;
    padding: 16px 0;

    &-wrap {
      display: flex;
      align-items: center;
    }
  }

  &__logo {
    width: 30px;
    height: 30px;
    object-fit: contain;
    margin: 0 15px 0 0;
  }

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
  }

  &__wrapper {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__circle {
    width: 84px;
    height: 84px;
    border-radius: 100%;
    margin: 0 auto;
  }

  &__icon {
    display: flex;
    width: 100%;
    height: 100%;
    transform: translate(-1%, -1%);
    align-items: center;
    justify-content: center;
  }

  &__description {
    margin: 24px auto;
  }

  h2 {
    color: #1a1a26;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 32px;
    font-weight: 600;
  }

  h3 {
    color: #1a1a26;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-weight: 600;
    display: none;
  }

  &__description-text {
    color: #67666e;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-weight: 400;
    padding-top: 8px;
    width: 100%;
  }

  &__offer {
    width: 100%;
    padding: 32px 24px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    margin-top: 44px;
  }

  &__offer-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__offer-item-wrapper {
    margin-top: 44px;
  }

  &__offer-item-border {
    border-bottom: 1px solid #f2f2f3;
    padding-bottom: 16px;
  }

  &__item-label {
    color: #808086;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 400;
  }

  &__item-description {
    color: #1a1a26;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 500;
  }

  @media (max-width: 530px) {
    padding: 0px 16px;

    h2 {
      display: none;
    }

    h3 {
      display: block;
      margin: 0 auto;
    }

    &__description-text {
      font-size: 16px;
    }
  }
}
