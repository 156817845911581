$blue-100: #1F44DD;
$blue-900: #194185;
$gray-0: #FFF;
$gray-50: #F9FAFB;
$gray-100: #E6E6E7;
$gray-200: #E4E7EC;
$gray-300: #D0D5DD;
$gray-400 :#98A2B3;
$gray-500 :#667085;
$gray-700: #344054;
$gray-800: #1A1A26;
$gray-900: #101828;
$neutral-200:#F7F7F7;
$purple-100:#EBE9FE;
$red-100:#FFE8EA;
$red-500:#EE071B;
$red-700:#DA4C53;
$green-100:#017130;
$success-50:#ECFDF3;
$success-500:#12B76A;
$success-700:#027A48;
$success-100:#D1FADF;
$error-300:#FDA29B;
$error-500:#F04438;
$warning-600: #DC6803